
import * as ipxRuntime$ruJWqXCRJV from '/opt/simploshop/node_modules/.pnpm/@nuxt+image@1.9.0_db0@0.3.1_ioredis@5.6.0_magicast@0.3.5/node_modules/@nuxt/image/dist/runtime/providers/ipx'
import * as cloudflareRuntime$35LLm8pMJJ from '/opt/simploshop/node_modules/.pnpm/@nuxt+image@1.9.0_db0@0.3.1_ioredis@5.6.0_magicast@0.3.5/node_modules/@nuxt/image/dist/runtime/providers/cloudflare'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 576,
    "md": 768,
    "lg": 992,
    "xl": 1200,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {
    "default": {
      "modifiers": {
        "format": "webp",
        "densities": [
          1,
          2
        ]
      }
    }
  },
  "provider": "ipx",
  "domains": [
    "vut.dev.simploshop.com"
  ],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "avif",
    "webp",
    "png",
    "webp"
  ]
}

imageOptions.providers = {
  ['ipx']: { provider: ipxRuntime$ruJWqXCRJV, defaults: {"maxAge":3600} },
  ['cloudflare']: { provider: cloudflareRuntime$35LLm8pMJJ, defaults: {"baseURL":"https://vut.dev.simploshop.com"} }
}
        